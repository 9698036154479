import styled from "styled-components";
import DatePicker from "react-datepicker";

export const StyledReportFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 25px;
  background-color: #0e2d54;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
`;

export const StyledDateRangeWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StyledDatePickerInputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: absolute;
    top: -18px;
    font-size: 14px;
    color: #ffffff;
    font-family: "Baloo 2", cursive;
    opacity: 0.5;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  font-weight: 700;
  font-size: 20px;
  color: #165b95;
  font-family: "Baloo 2", cursive !important;
  background: #ffffff;
  border: none;
  border: 2px solid #38a6de;
  border-radius: 20px;
  height: 43px;
  min-width: 200px;
  padding: 0 40px 0 26px;
  position: relative;

  &::placeholder {
    color: #165b95;
  }
`;

interface StyledTabButtonProps {
  active: boolean;
}

export const StyledTabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 25px;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
`;

export const StyledTabButton = styled.button<StyledTabButtonProps>`
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  color: ${(props) => (props.active ? "#165b95" : "rgb(13 45 84 / 25%)")};
  font-family: "Baloo 2", cursive !important;
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom-color 0.3s;

  &:hover {
    color: #165b95;
  }

  ${(props) =>
    props.active &&
    `
    border-bottom: 3px solid #165b95;
  `}
`;

export const StyledCurrentFilterDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  padding-bottom: 5px;

  p {
    font-size: 16px;
    color: #0d2d54;
    font-family: "Baloo 2", cursive !important;
  }
`;

export const StyledExportContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  padding-bottom: 5px;
`;
